






import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Ribbon extends Vue {
  @Prop({ default: "Coming Soon" }) label!: string;

  @Prop({ default: "top-right" }) position!: string;
}
